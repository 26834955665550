.App {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 14pt;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.Home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

body {
  background-color: #FFF6E4;
}

td {
  border: 1px solid black;
  border-collapse: collapse;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 25px;
  hyphens: auto;
}

thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

th {
  border-right: solid black 1px;
  border-left: solid black 1px;
}

th::after,
th::before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}

th::before {
  top: 0;
  border-top: 1px solid black;
  border-bottom: none;
  margin-top: -0.25px;
}

th::after {
  bottom: 0;
  border-bottom: 1px solid black;
}

th {
  font-weight: normal;
  padding: 12px 18px 12px 18px;
  text-align: left;
  background-color: #E6D6B9;
}

.long-th {
  width: 20%;
}

.extra-long-th {
  width: 40%;
}

td {
  vertical-align: top;
  border-top: none;
  padding: 12px 18px 12px 18px;
}

.loading-container {
  background-color: #FFF6E4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 300px;
}

.loading-content {
  font-size: 16pt;
  width: 400px;
}

.gif {
  margin-bottom: 10px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 25px 25px 25px;
  border-bottom: solid black 1px;
  background-color: #E6D6B9;
}

.title-text {
  font-size: 16pt;
}

.container {
  display: flex;
  flex-direction: row;
  margin: 0 25px 0 25px;
  min-height: 0;
}

.left-side {
  width: 415px;
  margin-right: 25px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 0;
}

.right-side {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.right-side-gap {
  width: 10px;
}

.form-title {
  width: 362px;
  background-color: #E6D6B9;
  border-style: solid solid none solid;
  border-width: 1px;
  padding: 12px 18px 12px 18px;
}

.main-form {
  width: 400px;
}

.form {
  width: 362px;
  border: solid black 1px;
  padding: 16px 18px 16px 18px;
  background-color: #FFEDCD;
  margin-bottom: 25px;
}

.description {
  margin-bottom: 25px;
  width: 400px;
}

.greeting {
  border: solid black 1px;
  padding: 18px;
  background-color: #FFEDCD;
}

.description-content {
  border-top: solid #b0a489 1px;
  border-bottom: solid #b0a489 1px;
  padding: 25px 0 25px 0;
}

mark {
  background-color: #FFEDCD;
}

/* Scrollbar */

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #c9bba1;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

/* Overrides browser's presets for input selected state */
textarea, input { 
  outline: none; 
}

input[type=text] {
  box-sizing: border-box;
  border: solid black 1px;
  background-color: #FFF6E4;
  margin: 8px 0 12px 0;
  width: 100%;
  font-size: 14pt;
  font-family: Georgia, 'Times New Roman', Times, serif;
  padding: 7px 10px 7px 10px;
}

input[type=text]:focus {
  border: solid black 2px;
  border-radius: 0px;
}

textarea {
  box-sizing: border-box;
  border: solid black 1px;
  background-color: #FFF6E4;
  margin: 8px 0 12px 0;
  width: 100%;
  height: 90px;
  font-size: 14pt;
  font-family: Georgia, 'Times New Roman', Times, serif;
  padding: 7px 10px 7px 10px;
  resize: vertical;
}

textarea:focus {
  border: solid black 2px;
  border-radius: 0px;
}

input[type=submit] {
  border: solid black 1px;
  background-color: #E6D6B9;
  padding: 7px 10px 7px 10px;
  font-size: 14pt;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin-top: 24px;
}

input[type=submit]:hover {
  background-color: #ccba99;

  transition: 0.3s;
  cursor: pointer;
}

/* DROPDOWNS */

.dropdown-container {
  box-sizing: border-box;
  width: 100%;
}

.drop-content {
  display: none;
  border-style: none solid solid solid;
  border-width: 1px;
  margin-top: 0;
  background-color: #FFF6E4;
}

.drop-content:hover {
  cursor: pointer;
}

.dropdown-spacer {
  height: 12px;
}

.show {
  display: block;
}

.dropbtn {
  display: flex;
  justify-content: space-between;
  border: solid black 1px;
  background-color: #FFF6E4;
  margin: 8px 0 0 0;
  width: 100%;
  font-size: 14pt;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: left;
  padding: 7px 10px 7px 10px;
  z-index: 1;
}

.dropbtn:hover {
  cursor: pointer;
}

.down-arrow {
  pointer-events: none;
  z-index: 0;
  font-size: 10pt;
  margin-top: 4px;
}

.up-arrow {
  pointer-events: none;
  z-index: 0;
  font-size: 10pt;
  margin-top: 4px;
}

#downColor {
  display: block;
}

#upColor {
  display: none;
}

#downFont {
  display: block;
}

#upFont {
  display: none;
}

.color-drop {
  width: 100%;
}

.drop-item {
  padding: 7px 10px 7px 10px;
}

#yellow {
  background-color: #FFEECD;
  border: solid #f2d08f 3.5px;
}

#orange {
  background-color: #FFE6CE;
}

#green {
  background-color: #E2EED5;
}

#blue {
  background-color: #F1F6F6;
}

#purple {
  background-color: #F3ECF8;
}

#serif {
  border-width: 1px;
  border-style: none none solid none;
  border-color: #B3A690;
}

#serif:hover {
  background-color: #E6D6B9;
  transition: 0.3s;
}

#sans-serif {
  border-width: 1px;
  border-style: none none solid none;
  border-color: #B3A690;
  font-family: 'Trebuchet MS', Arial, sans-serif;
}

#sans-serif:hover {
  background-color: #E6D6B9;
  transition: 0.3s;
}

#monospace {
  border-width: 1px;
  border-style: none none solid none;
  border-color: #B3A690;
  font-family: Courier, monospace;
}

#monospace:hover {
  background-color: #E6D6B9;
  transition: 0.3s;
}

#cursive {
  font-family: cursive;
}

#cursive:hover {
  background-color: #E6D6B9;
  transition: 0.3s;
}

/* ERRORS */

#empty-error {
  display: none;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ff3b3b;
}

#character-error {
  display: none;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ff3b3b;
}

/*===== MEDIA QUERIES =====*/

@media (min-width: 1175px) and (max-width: 1315px) {

  .Home {
    width: 100%;
    overflow: auto;
  }
  
  .left-side {
    width: 267px;
  }

  .form-title {
    background-color: #E6D6B9;
    border-style: solid solid none solid;
    border-width: 1px;
    padding: 12px 18px 12px 18px;
    width: 212px;
  }

  .title {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    margin: 25px 25px 0 25px;
    border: solid black 1px;
    background-color: #E6D6B9;
  }

  .description {
    width: 252px;
  }

  .main-form {
    width: 252px;
  }

  .form {
    width: 212px;
    border: solid black 1px;
    padding: 16px 18px 16px 18px;
    background-color: #FFEDCD;
  }
}

/* TABLET */

@media (min-width: 950px) and (max-width: 1174px) {

  .Home {
    width: 100%;
    overflow: visible;
    height: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin: 0 25px 25px 25px;
    min-height: 0;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
  
  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 25px 0;
    overflow-y: visible;
    overflow-x: visible;
    flex-shrink: 0;
  }
  
  .right-side {
    height: auto;
    width: 100%;
    overflow: visible;
  }

  .form-group {
    width: 80%;
  }

  .form-title {
    box-sizing: border-box;
    background-color: #E6D6B9;
    border-style: solid solid none solid;
    border-width: 1px;
    padding: 12px 18px 12px 18px;
    width: 100%;
    margin: 0;
  }

  .title {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    margin: 25px 25px 0 25px;
    border: solid black 1px;
    background-color: #E6D6B9;
  }

  .form {
    box-sizing: border-box;
    width: 100%;
    border: solid black 1px;
    padding: 16px 18px 16px 18px;
    background-color: #FFEDCD;
    margin: 0;
  }

  .description {
    width: 80%;
    margin: 0 0 25px 0;
  }

}

@media (min-width: 800px) and (max-width: 949px) {

  .Home {
    width: 100%;
    overflow: visible;
    height: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin: 0 25px 25px 25px;
    min-height: 0;
  }
  
  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 25px 0;
    overflow-y: visible;
    overflow-x: visible;
    flex-shrink: 0;
  }
  
  .right-side {
    height: auto;
    width: 100%;
    overflow: visible;
  }

  .form-group {
    width: 100%;
  }

  .form-title {
    box-sizing: border-box;
    background-color: #E6D6B9;
    border-style: solid solid none solid;
    border-width: 1px;
    padding: 12px 18px 12px 18px;
    width: 100%;
    margin: 0;
  }

  .title {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    margin: 25px 25px 0 25px;
    border: solid black 1px;
    background-color: #E6D6B9;
  }

  .form {
    box-sizing: border-box;
    width: 100%;
    border: solid black 1px;
    padding: 16px 18px 16px 18px;
    background-color: #FFEDCD;
    margin: 0;
  }

  .description {
    width: 100%;
    margin: 0 0 25px 0;
  }
}

@media (min-width: 430px) and (max-width: 799px) {

  .Home {
    width: 100%;
    overflow: visible;
    height: auto;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin: 0 25px 0 25px;
    min-height: 0;
  }
  
  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 25px 0;
    overflow-y: visible;
    overflow-x: visible;
    flex-shrink: 0;
  }
  
  .right-side {
    height: auto;
    width: 100%;
    overflow-x: auto;
    overflow-y: visible;
  }

  .form-group {
    width: 100%;
  }

  .form-title {
    box-sizing: border-box;
    background-color: #E6D6B9;
    border-style: solid solid none solid;
    border-width: 1px;
    padding: 12px 18px 12px 18px;
    width: 100%;
    margin: 0;
  }

  .title {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    margin: 25px 25px 0 25px;
    border: solid black 1px;
    background-color: #E6D6B9;
  }

  .form {
    box-sizing: border-box;
    width: 100%;
    border: solid black 1px;
    padding: 16px 18px 16px 18px;
    background-color: #FFEDCD;
    margin: 0;
  }

  .description {
    width: 100%;
    margin: 0 0 25px 0;
  }
}

@media (max-width: 429px) {

  .Home {
    width: 100%;
    overflow: visible;
    height: auto;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin: 0 25px 0 25px;
    min-height: 0;
  }
  
  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 25px 0;
    overflow-y: visible;
    overflow-x: visible;
    flex-shrink: 0;
  }
  
  .right-side {
    height: auto;
    width: 100%;
    overflow-x: auto;
    overflow-y: visible;
  }

  .form-group {
    width: 100%;
  }

  .form-title {
    box-sizing: border-box;
    background-color: #E6D6B9;
    border-style: solid solid none solid;
    border-width: 1px;
    padding: 12px 18px 12px 18px;
    width: 100%;
    margin: 0;
  }

  .title {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    margin: 25px 25px 0 25px;
    border: solid black 1px;
    background-color: #E6D6B9;
  }

  .title-text {
    font-size: 14pt;
  }

  .entry-counter {
    font-size: 12pt;
  }

  .form {
    box-sizing: border-box;
    width: 100%;
    border: solid black 1px;
    padding: 16px 18px 16px 18px;
    background-color: #FFEDCD;
    margin: 0;
  }

  .description {
    width: 100%;
    margin: 0 0 25px 0;
  }
}
